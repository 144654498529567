export default {
    bookings: [],
    benefitDetailsTransaccion: [],
    benefitsByContract: [],
    hotelPerformance: null,
    hotels: null,  
    lastTransactions: [],
    languages: [],
    newsletter: [],
    nextReservation: null,
    ownerInHouse: false,
    housing:[],
    profits: {
        anual: null,
        myProfits: []
    },
    myFiles:{
        // listOne: [],
        // listTwo: [],
        resources: [],
        myFilesOne: [],
        myFilesTwo: [],
    },
    selectedTabProfits: 0,
    typeDocument:[],
    cancellationReasons: [],
    exchangesSubscriptionInfo: null,
    staffContacts: []
}